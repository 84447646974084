import React, { useContext } from "react";
import { Link } from "react-scroll";

import { Container, Row, Col } from "react-bootstrap";

import GlobalContext from "../../context/GlobalContext";
import imgHero from "../../assets/image/bg/2.jpg";

const Hero = () => {
  const gContext = useContext(GlobalContext);

  return (
    <>
      {/* <!-- Hero Area --> */}
      <div
        className="co-working bg-image bg-overlay overlay-1 pb-13 pt-24 pt-lg-32 pb-lg-28 d-lg-flex align-items-center lg:min-h-vh-100"
        css={`
          background-image: url(${imgHero});
        `}
      >
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col lg="10" xl="9" data-aos="fade-up" data-aos-duration="1000">
              <div className="hero-content section-title dark-mode-texts text-center">
                <h1 className="gr-text-2 text-white fw-bold mb-12">
                  
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
        <Link
          to="elFact"
          spy={true}
          smooth={true}
          offset={-80}
          duration={1500}
          className="circle-40 border border-white gr-abs-bx-center mx-auto mt-13 goto"
          css={`
            cursor: pointer;
          `}
        >
          <i className="fas fa-chevron-down text-white mx-auto gr-text-8"></i>
        </Link>
      </div>
    </>
  );
};

export default Hero;
